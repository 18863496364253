import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import Axios from "axios"
import MdWrapper from "@/components/MarkdownWrap"

function Index(props) {
  const [markdown, setMarkdown] = useState(null)

  const getMd = () => {
    Axios.get(`${process.env.resourceUrl}docs/news/md/news20230404.md`).then(function (data) {
      setMarkdown(data.data)
    })
  }

  useEffect(() => {
    getMd()
  }, [])

  return (
    <Layout menuBlack={true}>
      <SEO page="News" />
      {markdown != null ? (
        <MdWrapper md={markdown} stl={{ marginTop: 0 }} />
      ) : (
        ""
      )}
    </Layout>
  )
}

export default Index